import { ApolloClient, ApolloProvider, DefaultOptions, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import cache from './cache'
import './index.scss'
import MainProvider from './modules/main/MainContext'
import reportWebVitals from './reportWebVitals'
import { getUserToken, setCookieForNonProductionEnvironments } from './utils/authentication'

const Main = lazy(() => import('./modules/main/scenes/Main'))

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  query: {
    // fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    // fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const authLink = setContext((_, { headers }) => {
  const token = getUserToken()
  setCookieForNonProductionEnvironments(token)

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Apollo-Require-Preflight': 'true',
    },
  }
})

const uploadLink = createUploadLink({ uri: `${process.env.REACT_APP_GRAPHQL_BACKEND}/graphql` })

const client = new ApolloClient({
  link: from([authLink, uploadLink]),
  cache,
  credentials: 'include',
  // connectToDevTools: process.env.NODE_ENV === 'development',
  defaultOptions,
})

const container = document.querySelector('#root')!

const root = createRoot(container)

root.render(
  /**
   * We need to disable StrictMode because ApolloClient has problems with it,
   * https://github.com/apollographql/apollo-client/issues/7436.
   */
  //  <StrictMode>
  <Suspense>
    <ApolloProvider client={client}>
      <MainProvider>
        <Router>
          <Main />
        </Router>
      </MainProvider>
    </ApolloProvider>
  </Suspense>
  /* </StrictMode> */
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

/**
 * Function taken from Apollo Client source code.
 *
 * https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
 */

import type { FieldPolicy, Reference } from '@apollo/client'

type KeyArgs = FieldPolicy<any>['keyArgs']

export function skipFirstPagination<T = Reference>(keyArgs: KeyArgs = false): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      let merged: T[] = []

      if (!isNaN(args?.input?.skip)) {
        merged = existing ? existing.slice(0) : []

        // Assume an offset of 0 if args.skip omitted.
        const { skip = 0 } = args?.input

        if (skip - merged.length > 0) {
          merged = merged.concat(Array(skip - merged.length).fill([]))
        }

        for (let i = 0; i < incoming.length; ++i) {
          merged[skip + i] = incoming[i]
        }
      } else {
        // It's unusual (probably a mistake) for a paginated field not
        // to receive any arguments, so you might prefer to throw an
        // exception here, instead of recovering by appending incoming
        // onto the existing array.
        merged.push.apply(merged, incoming as T[])
      }

      return merged
    },
  }
}

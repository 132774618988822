import { createContext, PropsWithChildren, useMemo, useState } from 'react'

type MainContextProps = {
  runConfetti: boolean
  setRunConfetti: (value: boolean) => void
}

export const MainContext = createContext<MainContextProps>({
  runConfetti: false,
  setRunConfetti: () => undefined,
})

export default function MainProvider({ children }: PropsWithChildren<{}>) {
  const [runConfetti, setRunConfetti] = useState(false)

  return (
    <MainContext.Provider value={useMemo(() => ({ runConfetti, setRunConfetti }), [runConfetti, setRunConfetti])}>
      {children}
    </MainContext.Provider>
  )
}

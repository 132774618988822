import Cookies from 'universal-cookie'

export const authenticate = (token: string, name?: string, lastName?: string, email?: string): void => {
  if (token) {
    localStorage.setItem('user_token', token)
  }

  if (name) {
    localStorage.setItem('user_name', name)
  }

  if (lastName) {
    localStorage.setItem('user_last_name', lastName)
  }

  if (name && lastName) {
    localStorage.setItem('user_full_name', `${name} ${lastName}`)
  }

  if (email) {
    localStorage.setItem('user_email', email)
  }
}

export const logOut = (): void => {
  localStorage.removeItem('user_token')
  setCookieForNonProductionEnvironments()

  localStorage.removeItem('user_full_name')

  localStorage.removeItem('user_email')

  localStorage.removeItem('user_picture')
}

export const getUserToken = (): string => localStorage.user_token

export const getUserFullName = (): string => localStorage.user_full_name
export const setUserFullName = (fullName: string) => {
  if (fullName) {
    localStorage.setItem('user_full_name', fullName)
  }
}

export const getUserFirstName = (): string => localStorage.user_full_name.split(' ')[0]
export const getUserLastName = (): string => localStorage.user_full_name.split(' ')[1]

export const getUserPicture = (): string => localStorage.user_picture
export const setUserPicture = (picture: string) => localStorage.setItem('user_picture', picture)

export const getUserEmail = (): string => localStorage.user_email
export const setUserEmail = (email: string) => {
  if (email) {
    localStorage.setItem('user_email', email)
  }
}
export const removeUserEmail = () => localStorage.removeItem('user_email')

export const clear = (): void => localStorage.clear()

export const setCookieForNonProductionEnvironments = (token?: string) => {
  //production should not need to have a cookie set as it shares the domain and can use local storage
  if (!process.env.REACT_APP_BUILD || process.env.REACT_APP_BUILD === 'production') return

  const cookies = new Cookies()

  // setting the cookie to undefined will be used when logging out the user
  if (!token) {
    cookies.remove('user_token')
  }

  //for localhost it should not be necessary to make any amendments in order to share cookies, default domain value suffices
  if (process.env.REACT_APP_BUILD === 'development') {
    cookies.set('user_token', token, { path: '/', domain: 'localhost' })
  }

  //for vercel preview environments we can hopefully use vercel.app as the top domain to share cookies on
  if (process.env.REACT_APP_BUILD === 'preview') {
    cookies.set('user_token', token, { path: '/', domain: 'staging-peacock.io' })
  }
}

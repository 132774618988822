import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import './app/index'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://ae9814c858164b88a72a35dc4422f91f@o1171057.ingest.sentry.io/6265197',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

/* @ts-ignore */
;(globalThis as any).VERSION = VERSION
/* @ts-ignore */
;(globalThis as any).COMMITHASH = COMMITHASH
/* @ts-ignore */
;(globalThis as any).BRANCH = BRANCH
